<template>
  <v-sheet 
    tile
    id="chatbot"
    class="fill-height px-2"
    color="transparent"
  >
    <v-sheet
      v-resize="headerResize"
      :elevation="elevation"
      ref="chat-header"
      class="chat-header px-4 pb-2"
    >
      <v-card-title 
        class="white--text pa-0"
        style="line-height: 1.25rem;"
      >
        <v-avatar 
          size="40"
        >
          <v-img
            :src="require('@/assets/help/img/driver-bot-avatar-72.png')"
          />
        </v-avatar>
        <p class="ml-4 my-1">
          <span class="title d-block text-overline font-weight-bold">Mel</span>
          <span class="subtitle d-block text-caption text--secondary">
            Bot de Operações
          </span>
        </p>
        <v-spacer />
        <v-btn
          v-if="closeable||dev"
          dark
          text
          small
          color="error"
          @click="close.toggle = true;"
        >
          Cancelar
          <!-- <v-icon>{{ icons.close }}</v-icon> -->
        </v-btn>
      </v-card-title>
      <v-progress-linear
        v-if="loading"
        indeterminate
        absolute
        bottom
        height="2"
        color="secondary"
        class="my-0"
      />
    </v-sheet>
    <bot-ui 
      :id="!!ticket ? ticket.id : null"
      :container="scrollContainer"
      :online="isOnline"
      :history="history"
      :style="{ 'padding-top': view.header.height+'px' }"
      @toggle-alert="toggleAlert"
    >
      <template v-slot:footer>
        <v-card
          v-if="scheduled&&idle"
          color="secondary"
          class="calendar-display mt-4 pa-0"
        >
          <header class="d-flex pa-4">
            <v-icon left>
              {{ icons.calendar }}
            </v-icon>
            <span>
              Agendamento: 
            </span>
            <v-spacer />
            <b>{{ schedule.start | readableDate }}</b>
          </header>
          <v-divider class="my-0 mx-4" />
          <v-card-actions class="d-flex">
            <v-spacer />
            <v-btn
              text
              small
              @click="reschedule"
            >
              Alterar horário
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </bot-ui>

    <v-dialog
      v-model="close.toggle"
      transition="slide-y-reverse-transition"
      overlay-opacity=".8"
      max-width="90vw"
    >
      <v-card color="black">
        <v-card-title>
          Cancelar chamado
        </v-card-title>

        <v-card-text>
          <span class="text-body-1">Tem certeza que deseja <b>encerrar este atendimento</b>?</span>
        </v-card-text>
        
        <v-card-actions>
          <v-btn
            text
            color="error"
            @click="close.toggle = false; closeTicket(close.message, true)"
          >
            Encerrar
          </v-btn>

          <v-spacer />

          <v-btn
            text
            @click="close.toggle = false"
          >
            Voltar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-sheet>
</template>

<style lang="scss">

  #chatbot {
    font-size: 16px;
    /* font-weight: 500; */
  }

  #chatbot .chat-header {
    position: fixed;
    left: 0;
    width: 100%;
    background: var(--v-background-base);
    z-index: 5;
  }

  .chat-header .title {
    font-size: .875rem !important;
    line-height: 1.5rem;
  }

  #chatbot .botui-actions-buttons-button {
    color: var(--v-accent-base);
    border: thin solid var(--v-accent-darken4);
  }
  #chatbot .botui-actions-buttons-button.offline {
    color: white;
    border: thin solid white;
    opacity: .5;
  }
  
</style>

<script>
  import {
    mdiChatRemove,
    mdiCalendarCheck
  } from '@mdi/js'
  import services from '@/services'
  import dictionary from '@/dictionary'
  import BotUi, { botui } from '@/components/BotUi.vue'
  const moment = require('moment');

  export default {
    props: {
      scrollContainer: {
        type: HTMLElement,
        default: () => null
      },
      script: {
        type: Object,
        default: () => null
      },
      info: {
        type: Object,
        default: () => null
      },
      ticket: {
        type: Object,
        default: () => null
      },
      tickets: {
        type: Object,
        default: () => {}
      },
      events: {
        type: Object,
        default: () => {}
      },
      calendar: {
        type: Object,
        default: () => null
      },
      user: {
        type: Object,
        default: () => null
      },
      buzzer: {
        type: Object,
        default: () => null
      },
      review: {
        type: Object,
        default: () => null
      },
      reviews: {
        type: Object,
        default: () => null
      },
      vehicles: {
        type: Array,
        default: () => []
      },
      hive: {
        type: Object,
        default: () => null
      },
      maintenance: {
        type: Object,
        default: () => null
      },
      elevation: {
        type: Number,
        default: 0
      }
    },

    components: {
      BotUi,
      // CalendarView: () => import('@/components/CalendarView'),
    },

    data: () => ({
      bot: botui,
      icons: {
        close: mdiChatRemove,
        calendar: mdiCalendarCheck,
      },
      view: {
        header: {
          height: 60,
        },
        calendarTooltip: {
          toggle: false,
          shown: false
        },
        loader: null
      },
      intro: [
        {
          interaction: 'message',
          delay: 1500,
          loading: true,
          content: 'Oi {{name}}! Eu sou a Mel, bot da operação Mobees. Prazer!'
        },
        {
          interaction: 'message',
          delay: 1000,
          loading: true,
          content: 'Fui criada para ajudar no relacionamento e manutenção dos equipamentos, e deixar nossa Parceria em fina sintonia! 😉'
        },
      ],
      greeting: [
        {
          interaction: 'message',
          delay: 500,
          loading: true,
          content: 'Oi {{name}}! Tudo bem?'
        },
      ],
      fup: [
        {
          interaction: 'message',
          delay: 500,
          loading: true,
          content: 'Opa!'
        },
        {
          interaction: 'message',
          delay: 500,
          loading: true,
          content: 'Oie!'
        },
        {
          interaction: 'message',
          delay: 500,
          loading: true,
          content: 'Vamos lá! 🦾'
        },
        {
          interaction: 'message',
          delay: 500,
          loading: true,
          content: 'Oi "sumid@" 😄'
        },
      ],
      locked: {
        inactive: [
          {
            interaction: 'message',
            delay: 500,
            loading: true,
            content: 'Este benefício é *exclusivo para Parceiros ativos*.'
          },
          {
            interaction: 'message',
            delay: 500,
            loading: true,
            content: 'Quando voltar a rodar você poderá voltar a usufruir o benefício, combinado?'
          },
          {
            interaction: 'message',
            delay: 500,
            loading: true,
            content: 'Até já! 😉'
          },
        ],
      },
      response: {
        sup: {
          success: [
            {
              interaction: 'message',
              delay: 500,
              loading: true,
              content: 'Prontinho! Em breve nossa equipe de Suporte Técnico entrará em contato com você ☺️'
            },
          ],
          error: [
            {
              interaction: 'message',
              delay: 500,
              loading: true,
              content: 'Não estou obtendo resposta do sistema de chamados no momento... ⏳'
            },
            {
              interaction: 'button',
              delay: 500,
              action: [
                {
                  text: 'Tentar novamente',
                  value: 'repeat',
                },
              ]
            }
          ]
        },
        calendar: {
          success: [
            {
              interaction: 'message',
              delay: 500,
              loading: true,
              update: true,
              content: 'Prontinho, seu agendamento está confirmado. Fique atento ao horário, a pontualidade é muito importante em nossa rotina!',
            },
            {
              interaction: 'event',
              event: 'onFinish'
            },
            {
              interaction: 'message',
              delay: 500,
              loading: true,
              content: 'Pedimos que *chegue o mais próximo do horário possível e com até 15 minutos de tolerância para atraso* 👍',
              update: true
            },
          ],
          error: [
            {
              interaction: 'message',
              delay: 500,
              loading: true,
              content: 'Não estou obtendo resposta do sistema de agendamento no momento... ⏳'
            },
            {
              interaction: 'button',
              delay: 500,
              action: [
                {
                  text: 'Solicitar atendimento',
                  value: [{
                    interaction: 'support',
                    delay: 1500,
                    category: 'Problema Técnico',
                    update: true
                  }],
                },
                // {
                //   text: 'Tentar novamente',
                //   value: 'repeat'
                // },
              ]
            }
          ]
        },
      },
      close: {
        toggle: false,
        message: {
          interaction: 'resolve',
          delay: 500,
          loading: true,
          content: 'Beleza! Qualquer coisa me chama aqui 😀'
        },
        cancel: false
      },
      comment: [{
        interaction: 'text',
        delay: 250,
        action: {
          placeholder: '',
          sub_type: 'area',
          value: ''
        }
      }],
      messages: [],
      current: null,
      node: null,
      data: {
        ticket: null,
        history: [],
        temp: {}
      },
      dictionary,
      loading: false,
      dev: process.env.NODE_ENV != 'production',
    }),

    computed: {
      transcript () {
        const messages = this.messages;
        return _.join(_.map(messages, m => {
          const from = _.has(m, 'human') && m.human ? this.name : 'Mel';
          return from + ': ' + m.content
        }), '\n');
      },

      dynamicGreeting () {
        return this.showIntro() ? this.intro : this.showFup() ? this.getFup() : this.greeting;
      },

      name () {
        const name = this.user.profile.fullname;
        return name!=null ? name.split(' ')[0] : null;
      },

      history () {
        const messages = JSON.parse(JSON.stringify(this.messages));
        return !_.isNil(this.ticket) ? messages : _.isEmpty(this.data.history) ? null : messages;
      },

      idle () {
        const ticket = this.data.ticket;
        const current = this.current;
        return _.isEmpty(current) || _.isNil(current) || ((current.interaction=='text') && ticket!=null);
      },

      reviewCritical () {
        return !_.isNil(this.review) && this.review.expired;
      },
      allowReviewOnline () {
        const history = this.reviews.history;
        const status = this.review.data;
        const max = 60;
        return !status.expired && _.some(_.reduce(history, (offline, rev) => {
          const days = moment().diff(rev.data, 'days')
          if (days<max) offline.push(_.indexOf(rev.labels, 'Revisão online')<0)
          return offline;
        }, []));
      },
      buzzerCache () {
        return this.buzzer.cached;
      },

      scheduled () {
        const calendar = this.schedule;
        return calendar!=null&&(calendar.issue==this.info.section||calendar.issue==null);
      },
      
      schedule () {
        const ticket = this.data.ticket;
        const calendar = this.calendar;
        const schedule = _.isNil(calendar) && ticket!=null && _.has(ticket.data, 'calendar') ? { issue: this.info.section, ...ticket.data.calendar } : this.calendar;
        return schedule;
      },

      hasSchedule () {
        const calendar = this.calendar;
        return !!calendar && moment().isBefore(calendar.start);
      },

      getData () {
        let data = {}
        _.each(this.messages, m => {
          if (_.has(m, 'data')) _.set(data, m.data[0], m[m.data[1]]);
        })
        const props = this.info.props;
        return { ...props, ...data, ...this.data.temp, ...(this.data.ticket!=null ? this.data.ticket.data : {}) };
      },

      logData () {
        let data = _.cloneDeep(this.getData);
        let log;
        if (_.has(data, 'temp')) {
          log = _.omit(data, data.temp);
        }else{
          log = data;
        }
        return log;
      },

      event () {
        const calendar = _.isNil(this.schedule) ? null : moment(this.schedule.start);
        return _.isNil(calendar) ? '–' : calendar.format('DD/MM') + ' às ' + calendar.hour() +'h';
      },

      closeable () {
        const ticket = this.data.ticket;
        const closeable = !(_.has(ticket, 'data')&&_.has(ticket.data, 'closeable')&&ticket.data.closeable) ? false : this.info.closeable===null||this.info.closeable===true;
        return closeable&&ticket!=null&&!this.close.cancel&&!this.scheduled;
      },

      urgent () {
        const tickets = _.omitBy(this.tickets, 'closed');
        return _.find(tickets, ['section', 'urgent']);
      },
      urgentPending () {
        return !_.isNil(this.urgent)&&(_.isNil(this.calendar)||moment().isAfter(this.calendar.start))
      },

      hours () {
        const data = _.clone(this.getData);
        let hours = _.has(data, 'hours') ? data.hours : [];
        hours = _.map(_.clone(hours), hour => {
          const props = _.split(hour, '|')
          const [ start, off=null, on_off=null, issue=null ] = props;
          return { 
            start, 
            off: (off=='' ? 1 : parseFloat(off)), 
            on_off: (on_off=='' ? null : on_off),
            issue: (issue=='' ? null : issue) 
          }
        });
        return hours;
      },

      hasHoursWithIssue () {
        const data = _.clone(this.getData);
        const hours = _.clone(this.hours);
        let processable = _.has(data, 'processable') ? data.processable : [];
        processable = _.some(hours, hour => {
          const [ category, ticket ] = _.split(hour.issue, '#');
          return !_.isNil(hour.issue) && !_.isNil(category) && _.indexOf(processable, _.trim(category))>=0;
        });
        let eligible = _.has(data, 'eligible') ? data.eligible : [];
        eligible = _.some(hours, hour => {
          const [ category, ticket ] = _.split(hour.issue, '#');
          return !_.isNil(hour.issue) && !_.isNil(category) && _.indexOf(eligible, _.trim(category))>=0;
        });
        return { processable, eligible }
      },

      buzzerActivity () {
        const data = _.clone(this.getData);
        const buzzer = _.clone(this.buzzer);
        const hours = _.clone(this.hours);
        let activity = _.has(data, 'activity') ? data.activity : { 
          inactive: [],
          active: [],
          status: moment().subtract(72, 'hours').isAfter(buzzer.timestamp.status) ? 0 : 1
        };
        if (!_.isEmpty(hours)) {
          const lastActive = _.last(activity.active.sort());
          const requestedDays = _.uniq(_.map(hours, hour => moment(hour.start).format('YYYY-MM-DD')));
          activity.requestedInactive = _.intersection(activity.inactive, requestedDays);
          activity.status = _.isEmpty(activity.requestedInactive) ? 1 : 
            _.isEmpty(activity.active)||_.every(requestedDays, day => moment(lastActive).isBefore(day)) ? 0 : 
            _.some(requestedDays, day => moment(lastActive).isAfter(day)) ? 3 : 2;
        }
        return activity;
      },

      hasIdentifiedIssue () {
        const data = _.clone(this.getData);
        return _.has(data, 'open');
      },

      emptyVehicleList () {
        return _.size(this.vehicles)==0;
      },

      selectedBenefit () {
        let benefit = null;
        const benefits = _.has(this.getData, 'benefits') ? this.getData.benefits : null;
        if (!!benefits&&_.has(this.getData, 'benefit')) {
          benefit = _.isObject(this.getData.benefit) ? this.getData.benefit : benefits[this.getData.benefit];
        }
        return benefit;
      },

      lock () {
        const inactive = this.user.profile.inactive || _.some(this.tickets, { closed: false, section: 'inactivity' });
        return { inactive };
      },
      
      now () {
        return Date.now();
      }
    },

    watch: {
      calendar: {
        immediate: true,
        deep: true,
        handler (calendar) {
          if (!_.isNil(calendar)) {
            const e = moment(calendar.start);
            if (moment().isBefore(e)) {
              if (!this.view.calendarTooltip.shown) {
                setTimeout($ => {
                  $.view.calendarTooltip.toggle = true;
                  $.view.calendarTooltip.shown = true;
                }, 3000, this);
                setTimeout($ => {
                  $.view.calendarTooltip.toggle = false;
                }, 15000, this);
              }
            }else{
              // this.$emit('save-calendar', null);
            }
          }
        }
      },
      ticket: {
        deep: true,
        handler (ticket) {
          if (this.ticket!=null) {
            const newMessages = _.drop(ticket.messages, this.messages.length);
            console.log('new messages', newMessages);
            if (newMessages.length>0) {
              const m = JSON.parse(JSON.stringify(ticket.messages));
              this.data.history = [...m];
              this.messages = [...m];
            }
            const confirmation = _.every(ticket.messages, message => {
              const support = _.has(message, 'support') && message.support;
              const read = _.has(message, 'read') ? message.read : false;
              const c = support ? read : true;
              return c;
            });
            if (!confirmation) this.setReadConfirmation(ticket.messages);
          }else{
            if (!_.isNil(this.data.ticket)) {
              setTimeout(($) => {
                $.close.cancel = true;
                $.bot.action.hide();
              }, 250, this);
            }
          }
        }
      },
      loading (b) {
        this.$emit('loading', b);
      }
    },

    methods: {
      ...services,

      headerResize () {
        const h = this.$refs['chat-header'].$el.clientHeight;
        console.log(h);
        if (h>0) {
          this.view.header.height = h;
        }else{
          setTimeout($ => {
            $.headerResize();
          }, 250, this);
        }
      },

      reschedule (cancelable) {
        this.bot.action.hide();
        const section = this.data.ticket.section;
        let script = _.has(this.script, section+'.fup') ? this.script[section+'.fup'].reschedule : _.has(this.script, 'fup') ? this.script.fup.reschedule : this.script['reschedule.fup'].reschedule;
        if (cancelable===false) {
          script = _.map(script, msg => {
            if (_.has(msg, 'action')) {
              msg.action = _.reject(msg.action, option => _.startsWith(option.text, 'Cancelar'))
            }
            return msg;
          })
        }
        this.handleChat(script);
      },

      showIntro () {
        const intro = localStorage.getItem('chatbot-intro');
        if (_.isNil(intro)) localStorage.setItem('chatbot-intro', true);
        return _.isNil(intro);
      },

      showFup () {
        const fup = localStorage.getItem('chatbot-last-interaction');
        localStorage.setItem('chatbot-last-interaction', new Date().valueOf());
        return moment().diff(fup,'h') < 1 || this.name==null;
      },

      getFup () {
        return this.fup[_.random(this.fup.length-1)];
      },

      startChat () {
        let ticket = _.clone(this.ticket);
        const data = _.clone(this.getData);
        let start = this.script.start;
        const restart = _.has(data, 'restart')&&data.restart;
        if (_.isNil(ticket)||restart) {
          if (restart) {
            const messages = _.map(JSON.parse(JSON.stringify(ticket.messages)), m => m.hidden=true);
            data = _.omit(data, ['restart']);
            data.restarted = true;
            ticket = this.setTicketController(messages, data);
          }
          const locked = _.has(data, 'lock')&&this.lock[data.lock];
          if (locked) {
            start = this.locked[data.lock];
          }
          start = _.concat(this.dynamicGreeting, start);

        }else {
          ticket = this.setTicketController();
          const last = _.last(this.messages);
          if (_.isNil(last)) {
            start = _.concat(this.dynamicGreeting, start);
          }else{
            if (ticket.section=='review'&&!_.isNil(this.urgent)&&this.scheduled&&this.calendar.issue!='urgent') {
              start = [
                {
                  "interaction": "message",
                  "delay": 1000,
                  "loading": true,
                  "content": "Identificamos um problema com o seu Buzzer. *Seu agendamento para {{event}} precisará ser reagendado*."
                },
                {
                  "interaction": "message",
                  "delay": 1000,
                  "loading": true,
                  "content": "Selecione o horário mais próximo possível, por favor..."
                },
                {
                  "interaction": "calendar",
                  "eventType": "MAN",
                  "delay": 500
                }
              ];
            }else if (ticket.section=='review'&&_.has(ticket.category, 'child')&&ticket.category.child=='Revisão Online'&&_.has(ticket, 'data')&&ticket.data.status==2&&!this.scheduled) {
              start = this.script.fup['schedule']
            }else if ((_.has(last, 'support')&&last.support)||(ticket.section=='general'&&_.has(last, 'human')&&last.human)) {
              start = this.comment;
            }else{
              if (_.has(last, 'next')) {
                if (_.has(last.next, 'update')&&last.next.update) last.next.update = false;
                start = last.next;
              }else if (ticket.closed&&_.has(this.script, 'fup')&&_.has(this.script.fup, 'closed')) {
                start = this.script.fup.closed;
              }else{
                start = null;
              }
            }
          }
        }
        setTimeout(this.handleChat, 500, start);
      },

      setTicketController (messages=null, data=null) {
        messages = !!messages ? messages : JSON.parse(JSON.stringify(this.ticket.messages));
        this.data.history = [...messages];
        this.messages = [...messages];
        const ticket = _.clone(this.ticket);
        if (!!data) ticket.data = data;
        this.data.ticket = _.assign({}, ticket);
        return ticket;
      },

      handleChat (script, end) {
        if (this.close.cancel&&_.isNil(end)) {
          this.bot.action.hide();
          return;
        }
        console.log(script);
        if (_.isArray(script)) {
          if (_.isEmpty(script)) {
            if (_.isNil(this.data.ticket)) {
              return false;
            }else{
              if (this.data.ticket.assignee=='SUP') {
                script = this.comment;
              }else{
                this.current = null;
                return false;
              }
            }
          }
          let next = _.first(script);
          this.current = next;
          switch (next.interaction) {
            case 'event': 
              this.handleEvent(script);
              break;
            case 'fork': 
              this.handleFork(script);
              break;
            case 'jump':
              this.handleJump(script);
              break;
            case 'message': 
              this.sendMessage(script);
              break;
            case 'button':
            case 'select':
            case 'text':
            case 'file':
              setTimeout(this.requireAction, next.delay, script);
              break;
            case 'calendar':
              if (_.has(next, 'value')) {
                setTimeout(($, slot, script) => {
                  $.setSelectedSlot(slot, script);
                }, next.delay, this, next.value, _.drop(script));
              }else{
                this.getCalendarSlots(script);
              }
              break;
            case 'unschedule':
              this.cancelSelectedSlot(script);
              break;
            case 'hours':
              if (_.has(next, 'value')) {
                this.sendMessage(script);
              }else{
                this.getHistoryHours(script);
              }
              break;
            case 'process':
              this.processData(script);
              break;
            case 'benefits':
              if (!!this.selectedBenefit) {
                this.requestBenefit(script);
              }else{
                this.getBenefits(script);
              }
              break;
            case 'flashmob':
              if (_.has(next, 'accepted')) {
                this.rsvpEvent(script, next.accepted);
              }else{
                this.getEvents(script);
              }
              break;
            case 'email':
              this.sendEmail(script);
              break;
            case 'cookie':
              this.setCookie(script);
              break;
            case 'support':
              this.assignTicket(next, _.drop(script));
              break;
            case 'resolve':
              if (_.isNil(this.data.ticket)) {
                next.interaction = 'message';
                this.sendMessage([next]);
              }else{
                this.closeTicket(next);
              }
              break;
            default:
              return false;
          }
        }else{
          if (_.isNil(script)) return;
          if (_.isString(script)) {
            script = _.has(this.script.nodes, script) ? this.script.nodes[script] : this.script[script];
          }else{
            script = script.type=='sup'||script.type=='calendar' ? 
              this.response[script.type][script.value] : 
              script.value=='repeat' ? [_.findLast(this.messages, ['interaction', 'support'])] : 
              _.has(script, 'next') && _.isArray(script.next) ? script.next : 
              _.has(script, 'value') && _.isArray(script.value) ? script.value :
              _.has(this.script.nodes, script.value) ? this.script.nodes[script.value] : 
              _.has(this.script, script.value) ? this.script[script.value] : this.script[script.value];
          }
          this.handleChat(script, end);
        }
      },

      handleFork (script) {
        const conditions = _.clone(_.first(script)).conditions;
        let next = true;
        const $ = this;
        _.each(conditions, condition => {
          const checker = new Function('$', 'return ' + (_.has(condition.var) ? '$.'+condition.var+condition.operator+condition.value : condition.expression));
          console.log(condition.expression, checker($));
          if (checker($)) {
            next = condition.next;
            return;
          }
        });
        if (next===true) {
          next = _.tail(script)
        }
        this.handleChat(next);
      },

      handleEvent (script) {
        let message = _.clone(_.first(script));
        if (_.has(this.info, 'events')&&_.has(this.info.events, message.event)) {
          this.data.ticket = _.merge(this.data.ticket, _.clone(this.info.events[message.event]))
        }
        const next = _.tail(script);
        if (_.has(message, 'update')&&message.update) {
          this.updateTicket(message, next);
        }else{
          this.handleChat(next);
        }
      },

      handleJump (script) {
        const jump = _.first(script);
        if (_.isString(jump.next)&&_.startsWith(jump.next, '$')) {
          const getNext = new Function('$', 'return ' + jump.next);
          jump.next = getNext(this);
        }
        if (_.has(jump.next, 'category')){
          const { value: category, child: subcategory } = jump.next.category;
          if (this.hasTicket(category, subcategory, false)) return;
        }
        setTimeout(($, jump) => {
          if (!_.has(jump.next, 'new')||jump.next.new) {
            // create new ticket
            if (_.has(jump, 'update')&&jump.update) {
              const { next, ...message } = { ..._.clone(jump), interaction: 'message' };
              $.sendMessage([message, {...jump, update: false }]);
            }else{
              $.$emit('open-ticket', null, jump.next);
            }
          }else{
            // switch section of ongoing script
            if ($.data.ticket==null) {
              $.openTicket(jump, jump.next);
            }else{
              $.updateTicket(jump, jump.next);
            }
          }
        }, _.has(jump, 'delay') ? jump.delay : 500, this, jump);
      },

      sendMessage (script) {
        let message = _.clone(_.first(script));
        console.log('sendMessage', message, script);
        message.content = this.fillData(message.content, message.params);
        this.bot.message.add(message).then((index) => {
          message.timestamp = moment.utc().format('YYYY-MM-DD HH:mm:ss');
          const next = _.has(message, 'next') ? message.next : _.tail(script);
          this.saveChat(message, next);
          if (_.has(message, 'update')&&message.update) {
            if (_.isNil(this.data.ticket)) {
              this.openTicket(message, next);
            }else{
              this.updateTicket(message, next);
            }
          }else{
            this.handleChat(next);
          }
        });
      },

      requireAction (script) {
        const config = this.prefillAction(_.clone(_.first(script)));
        if (_.has(config.action, 'options')&&_.startsWith(config.action.options, '$')) { 
          config.action.options = this[_.replace(config.action.options, '$', '')]();
        }
        console.log('requireAction...', config, script);
        this.bot.action[config.interaction](config).then((option) => {
          console.log('requireAction =>', option, script);
          option.timestamp = moment.utc().format('YYYY-MM-DD HH:mm:ss');
          option.interaction = config.interaction;
          if (_.has(config, 'category')) option.category = config.category;
          if (_.has(config, 'ticket')&&!config.ticket) option.ticket = false;
          option.human = true;
          option.content = option.text;
          let next = _.clone(option);
          let data = {};
          if (_.has(option, 'data')) {
            _.set(data, option.data[0], option[option.data[1]]);
            if (_.indexOf(option.data[0], 'profile.')) {
              const field = _.replace(option.data[0], 'profile.', '');
              const value = option[option.data[1]];
              this.updateProfile(field, value);
            }
          }
          if (_.has(option, 'closeable')) {
            data['closeable'] = option.closeable;
          }
          if (_.has(option, 'next')) {
            if (option.next===true) {
              next.next = _.tail(script);
            }else{
              if (option.value=='repeat'||_.isArray(option.next)) {
                next = _.clone(option.next);
              }else if (option.next=='file') {
                next = _.concat([{
                  interaction: 'file',
                  delay: 500,
                }], _.tail(script));
              }
              
              next.value = option.next;
            }
          }else if (option.type=='calendar') {
            next.interaction = option.type;
            const calendar = _.clone(next.value);
            data['calendar'] = calendar;
            if (_.isNil(this.data.ticket)||_.isNil(this.data.ticket.data)||!_.has(this.data.ticket.data, 'slots')) {
              data['slots'] = _.reduce(config.action, (slots, hours) => {
                slots.push(..._.map(hours, 'start'));
                return slots;
              }, []);
            }
            next = [next, ..._.tail(script)];
          }else if (option.type=='hours') {
            next.interaction = option.type;
            const history = _.clone(config.action);
            const average = _.clone(config.average);
            const hours = _.map(_.clone(next.value), 'start');
            const daily = {};
            const total = _.reduce(history, (total, day, d) => {
              if (!(d in daily)) daily[d] = 0;
              _.each(day, hour => {
                const available = _.indexOf(hours, hour.start)>=0 ? hour.off : 1-hour.compensated;
                daily[d] += _.clamp(1-available, 0, 1);
                total += available;
              })
              return total;
            }, 0);
            const requested = _.map(_.clone(next.value), hour => {
              let value = `${hour.start}|off|on_off|issue`
              value = _.replace(value, 'off', _.has(hour, 'off') ? hour.off : '');
              value = _.replace(value, 'on_off', _.has(hour, 'on_off') ? hour.on_off : '');
              value = _.replace(value, 'issue', _.has(hour, 'issue') ? hour.issue.category + ' #' + hour.issue.key : '');
              return value;
            });
            data['history'] = history;
            data['hours'] = requested;
            data['total'] = total;
            data['daily'] = daily;
            data['average'] = average;
            if (_.has(config, 'next')) {
              next.value = config.next;
              option.value = config.next;
            }else{
              next = _.tail(script);
            }
          }else if (option.type=='file') {
            option.interaction = option.type;
            next = _.tail(script);
          }
          if (_.has(option, 'section')) {
            next.section = option.section;
          }
          this.saveChat(option, next);
          if (_.has(next, 'value')&&_.isString(next.value)) {
            this.trackEvent('chatbot', 'user interaction', next.value);
          }
          this.data.temp = { ...this.data.temp, ..._.clone(data) }
          if (_.isNil(this.data.ticket)) {
            if (_.has(option, 'ticket')&&!option.ticket) {
              this.handleChat(next);
            }else{
              this.openTicket(config, next);
            }
          }else{
            if (option.interaction=='file') {
              this.attachTicket(option, next);
            }else{
              if (_.has(option, 'update')&&!option.update) {
                this.handleChat(next);
              }else{
                this.updateTicket(config, next); 
              }
            }
          }
        });
      },

      saveChat (message, next) {
        message.type = 'text';
        message.next = next;
        if (!_.isNil(this.data.ticket)&&_.has(message, 'data')) {
          _.set(this.data.ticket.data, message.data[0], message[message.data[1]]);
          console.log(message.data[0], message[message.data[1]])
        }
        this.messages.push(JSON.parse(JSON.stringify(message)));
        // this.$emit('save-chat', this.messages);
      },

      prefillAction (config) {
        if (_.has(config.action, 'sub_type')&&config.action.sub_type=='date') config.action.value = moment().subtract(1, 'd').format('YYYY-MM-DD');
        if (_.has(config.action, 'sub_type')&&config.action.sub_type=='time') {
          const data = this.data.ticket.data;
          config.action.min = _.reduce(['time.start', 'interval.1.start', 'interval.1.end', 'interval.2.start', 'interval.2.end', 'interval.3.start', 'interval.3.end'], (min, t) => {
            return _.has(data, t) && (_.isNil(min) || parseInt(_.replace(data[t], ':', ''))>parseInt(_.replace(min, ':', ''))) ? data[t] : min;
          }, null);
          config.action.max = _.has(data, 'time.end') ? data['time.end'] : null;
          config.action.value = _.isNil(config.action.min) ? moment().startOf('h').format('HH:mm') : config.action.min;
        }
        return config;
      },

      fillData (text, params) {
        if (_.startsWith(text, '$')) {
          if (_.isNil(params)) {
            text = this[_.replace(text, '$', '')]();
          }else{
            text = this[_.replace(text, '$', '')](...params);
          }
        }
        const regex = /\{\{(.*?)\}\}/gim;
        const $ = this;
        text = _.replace(text, regex, (match, key) => {
          console.log(match, key);
          return _.get($, key);
        });
        const data = _.clone(this.getData);
        if (!_.isEmpty(data)&&_.indexOf(text, '{{')>=0) {
          _.each(data, (data, d) => {
            text = _.replace(text, new RegExp('{{'+d+'}}', 'g'), data);
          });
        }
        return text;
      },
      
      getTranscript (messages) {
        const transcript = _.reduce(messages, (transcript, m) => {
          const human = _.has(m, 'human') && m.human;
          const from = human ? this.name : 'Mel';
          const hidden = _.has(m, 'hidden') && m.hidden;
          const content = m.interaction=='file'&&_.isArray(m.value) ? _.join(_.map(m.value, 'name')) : m.content;
          const msg = hidden ? 
            content : 
            human ? 
              '*' + from + ': ' + content + '*' : 
              from + ': ' + content;
          
          if ((!transcript.quote&&!hidden)||(transcript.quote&&hidden)) {
            transcript.text += '{quote}';
          }
          transcript.quote = !hidden;
          transcript.text += msg + '\n\n';
          return transcript;
        }, { text: '', quote: false });
        return transcript.text + (transcript.quote ? '{quote}' : '');
      },

      getMessageLog (messages) {
        return JSON.stringify(_.map(_.cloneDeep(messages), (m, i) => {
          m.next = _.has(m, 'next') && i < messages.length-1 ? null : m.next;
          if (m.interaction=='file'&&_.isArray(m.value)) {
            const files = _.map(m.value, file => {
              return { ...file, url: '@'+file.name }
            });
            m.content = _.join(_.map(files, file => `![${file.name}](${file.url})`));
            m.value = _.join(_.map(files, 'url'), ', ');
          }
          // m.text = m.content;
          const { interaction, content, value, human=false, hidden=false, timestamp, next=null, support=false, read=null } = m;
          return { interaction, content, value, human, hidden, timestamp, ...(!!next ? { next } : {}), ...(!!read ? { read } : {}), ...(support ? { support } : {}) }
        }))
      },

      setReadConfirmation (messages) {
        this.loading = true;

        const log = JSON.stringify({
          section: this.data.ticket.section,
          category: this.data.ticket.category,
          data: _.clone(this.logData),
          messages: this.getMessageLog(_.map(messages, message => {
            if (_.has(message, 'support')&&message.support) message['read'] = true;
            return message;
          }))
        });
        const critical = _.has(this.data.ticket, 'critical') ? this.data.ticket.critical : null;

        const comment = '*' + this.name + ':* 👀';

        const data = {
          cpf: this.info.cpf,
          authTk: this.info.authTk,
          issueId: this.data.ticket.key,
          comment,
          critical,
          log,
          public: false,
          category: null,
          subcategory: null
        }

        this.$api({
          url: '/addticketcomment',
            method: 'POST',
            data
          })
          .then(response => {
            console.log('setReadConfirmation => ',response);

            this.trackEvent('support', 'chatbot read confirmation');
          })
          .catch(error => {
            this.handleError(error, false);
          })
          .finally(() => {
            this.loading = false;
          });
      },

      hasTicket (category, subcategory, closed=null) {
        return _.some(this.tickets, ticket => {
          // console.log(ticket.closedAt, moment().diff(ticket.closedAt, 'd'));
          return (closed==null||ticket.closed==closed) && !_.isNil(ticket.category) && ticket.category.value==category && ticket.category.child==subcategory;
        });
      },

      buzzerActivityCheck () {
        const data = this.getData;
        let days;
        if (!_.has(data, 'history')) {
          if (_.has(data, 'activity')) {
            days = _.clone(data.activity);
          }else{
            return 'ℹ️ Não foi possível fazer a verificação de atividade do Buzzer';
          }
        }else{
          days = _.clone(data.history);
          // verify Buzzer activity by day
          days = _.reduce(days, (processed, hours, day) => {
            const list = _.some(hours, hour => {
              return hour.off<1 || (hour.on_off!=null&&_.indexOf(hour.on_off, '1')>=0)
            }) ? 'active' : 'inactive';
            processed[list].push(day);
            return processed;
          }, { active: [], inactive: [] });
          // remove history before creating ticket
          this.data.temp.activity = _.clone(days);
          this.$delete(this.data.temp, 'history');
        }
        let message = '🟢 Houve atividade do Buzzer nos dias selecionados.';
        if (days.inactive.length>0) {
          const formatted = _.map(days.inactive.sort(), (day) => moment(day).format('DD/MM'));
          message = `🔴 Não houve atividade do Buzzer ${formatted.length>1 ? 'nos dias' : 'no dia'} ${_.join(formatted, ', ')}`
        }
        return message;
      },

      getHoursIntervals (hours, pending=true) {
        const driver = _.clone(this.user);
        let brief = [];
        if (this.data.ticket!=null&&_.has(this.data.ticket, 'data')) {
          if (_.isNil(hours)&&pending) brief.push(
              '*Parceiro:* '+driver.profile.fullname, 
              '*Cod Device:* '+driver.profile.buzzer.code, 
              '*Buzzer:* '+driver.profile.buzzer.number
            );
          // format days & hours for analysis
          const slots = _.isNil(hours) ? this.hours : hours;
          let slotsByDay = _.groupBy(slots, hour => moment(hour.start).format('YYYY-MM-DD'));
          slotsByDay = _.mapValues(slotsByDay, day => {
            return _.keyBy(day, hour => moment(hour.start).hour());
          });
          const routine = _.reduce(slotsByDay, (text, hours, day) => {
            const d = moment(day);
            let total = 0;
            const intervals = _.map(hours, (hour, h) => {
              const val = _.isNil(hour.off) ? 1 : (hour.off>1) ? 1 : hour.off;
              total += val;
              const on_off = hour.on_off=='null' ? '–' : _.reduce(_.split(hour.on_off), (on, i) => on + (parseInt(i) * 5), 0);
              return '• '+h+'h ('+val+') | ' + `on_off: ${on_off}min` + (_.isNil(hour.issue) ? '' : ' – '+hour.issue);
            });
            text += '> Dia '+(d.date())+'/'+(d.month()+1)+': (Σ '+total+') \n';
            return text + _.join(intervals, ' \n') + '\n';
          }, '');


          brief.push(
            '*Rotina '+(_.isNil(hours) ? 'informada' : pending ? 'a compensar' : 'compensada automaticamente')+':* \n' + routine 
          );
          if (pending) {
            const cpf = this.rawCPF(driver.cpf);
            const link = this.$api.defaults.baseURL+'/admin/adm/workhours/add/?cpf='+cpf+'&ticket='+this.data.ticket.id+'&horas='+_.join(_.map(_.clone(slots), hour => {
              return _.replace(hour.start, ' ', 'T')+'v'+(!_.isNil(hour.off) ? (hour.off>1) ? 1 : hour.off : 1)
            }))
            brief.push(
              ' \n [Compensar|'+link+']'
            );
          }
          if (_.isNil(hours)&&pending) {
            const createdAt = this.data.ticket.createdAt;
            const reviewedAt = moment(this.reviews.reviewedAt).isValid() ? moment(createdAt).isAfter(this.reviews.reviewedAt) ? this.reviews.reviewedAt : this.reviews.reviewedBefore : null;
            let reviewDays = moment(reviewedAt).isValid() ? moment(createdAt).diff(reviewedAt, 'days') : '–';

            brief.push(
              '*Última visita:* '+moment(reviewedAt).format('DD/MM')+' ('+reviewDays+' dias)',
              '*Cache:* '+this.buzzerCache,
              '*Taxista:* '+(driver.profile.job.service_model=='Tx' ? 'Sim' : 'Não')
              );
          }
          brief = _.join(brief, '\n');
        }
        return brief;
      },

      processData (script) {
        const data = this.getData;
        if (this.info.section=='hours') {
          const categories = _.has(data, 'processable') ? data.processable : []
          let hours = _.clone(this.hours);
          const average = _.has(data, 'average')&&data.average!=null ? _.ceil(data.average) : null;
          const daily = _.has(data, 'daily') ? data.daily : {};
          const total = _.has(data, 'total') ? data.total : 0;
          if (total<=40) {
            let limited = false;
            let openTicket = false;
            let days = _.groupBy(hours, hour => moment(hour.start).format('YYYY-MM-DD'));
            hours = _.reduce(days, (result, day, d) => {
              const automatic = _.some(day, hour => {
                const [ category, ticket ] = _.split(hour.issue, '#');
                if (!_.isNil(ticket)) {
                  const ticketData = _.find(this.tickets, ['key', ticket]);
                  if (!openTicket&&!_.isNil(ticketData)) openTicket = ticketData.closed==null;
                }
                return !_.isNil(hour.issue) && !_.isNil(category) && _.indexOf(categories, _.trim(category))>=0;
              });
              // limit automatic hours to average
              if (automatic&&average!=null) {
                const computed = d in daily ? daily[d] : 0;
                limited = _.reduce(day, (limited, hour) => {
                  if (limited.total+computed<average) {
                    if (limited.total+hour.off>average) {
                      hour.off = limited.total + hour.off - average;
                    }
                    limited.hours.push(hour);
                    limited.total += hour.off;
                  }
                  return limited;
                }, { total: 0, hours: [] });
                day = limited.hours;
              }
              result[automatic ? 'automatic' : 'manual'].push(...day);
              return result;
            }, { requested: hours, automatic: [], manual: [] });


            if (_.size(hours.automatic)>0) {
              this.loading = true;
              const data = {
                cpf: this.info.cpf,
                authTk: this.info.authTk,
                hours: _.map(_.clone(hours.automatic), hour => {
                  return { date: hour.start, value: hour.off }
                })
              };
              script = _.tail(script);
  
              this.$api({
                url: '/setworkhours',
                  method: 'POST',
                  data
                })
                .then(response => {
                  console.log('setWorkHours => ', response);

                  script = [
                    {
                      interaction: 'message',
                      content: '$getHoursIntervals',
                      params: [hours.automatic, false],
                      hidden: true,
                      update: true
                    },
                    {
                      interaction: 'message',
                      delay: 500,
                      loading: true,
                      content: 'Suas horas foram conferidas e ajustadas'+(limited===false ? '.' : ' levando em consideração o seu histórico de horas.')+(openTicket ? 'Reforçamos que essas horas estão sujeitas a revisão/cancelamento em caso de falta ou remarcação do chamado de manutenção.' : ''),
                      update: true
                    }
                  ];
                  if (_.size(hours.manual)>0) {
                    script.push(
                      {
                        interaction: 'message',
                        content: '$getHoursIntervals',
                        params: [hours.manual, true],
                        hidden: true,
                        update: true,
                      },
                      {
                        interaction: 'message',
                        delay: 500,
                        loading: true,
                        content: 'Parte das horas solicitadas foram encaminhadas para nossa equipe de análise.',
                      },
                      {
                        interaction: 'message',
                        delay: 500,
                        loading: true,
                        content: 'Em breve entraremos em contato com você ☺️',
                        update: true
                      }
                    );
                  }else{
                    script.push({
                      interaction: 'resolve',
                      delay: 500,
                      loading: true,
                      content: 'Até a próxima! 😉'
                    });
                  }

                  this.handleChat(script);
                })
                .catch(error => {
                  let errors = [{
                    interaction: 'message',
                    content: 'Ocorreu um erro na compensação automática: '+JSON.stringify(error),
                    hidden: true,
                  }]
                  if (_.has(error.response, 'data')&&_.has(error.response.data, 'ok')&&_.size(error.response.data.ok)>0) {
                    const ok = _.map(error.response.data.ok, 'date');
                    hours.automatic = _.filter(hours.automatic, hour => _.indexOf(ok, hour.start)>=0)
                    errors.push({
                        interaction: 'message',
                        content: '$getHoursIntervals',
                        params: [hours.automatic, false],
                        hidden: true,
                      })
                  }
                  script.unshift(...errors);
                  this.handleChat(script);
                  this.handleError(error, false);
                })
                .finally(() => {
                  this.loading = false;
                });
            }else{
              this.handleChat(_.tail(script));
            }
          }else{
            // max 40 hours must be analyzed manually
            script[0] = {
              interaction: 'message',
              content: '*As horas solicitadas excedem o limite máximo da compensação*, mas não se preocupe, sua solicitação será encaminhada para o time de análise.',
              delay: 1000,
              loading: true,
            }
            this.handleChat(script);
          }
          console.log('process hours:', hours, total);
        }else{
          this.handleChat(_.tail(script));
        }
      },

      getSupportedVehicles () {
        const available = _.reduce(this.vehicles, (available, vehicle) => {
          if (vehicle.status==2) available.push({
            "text": vehicle.model,
            "value": vehicle.model,
            "data": [
              "model",
              "text"
            ],
            "next": "014.1",
            "ticket": false
          })
          return available;
        }, []);
        return available;
      },
      
      toggleAlert (msg) {
        this.$emit('toggle-alert', msg);
      },

      openTicket (message, next) {
        this.loading = true;

        let data = _.clone(this.info);

        const logData = _.clone(this.logData);
        
        let category = null;
        if (_.has(next, 'category')) {
          category = _.clone(next.category);
        }else if (_.has(data.props, 'category')) {
          category = _.clone(data.props.category);
        }

        let summary = '';
        if (_.has(category, 'value')) {
          data.category = category.value;
          summary = category.value;
        }
        if (_.has(category, 'child')) {
          category.child = this.fillData(category.child);
          data.subcategory = category.child;
          summary += ' - ' + category.child;
        }
        
        const testing = this.dev ? '[TESTE] ' : '';
        data.assunto = testing + (category!=null ? summary : data.issue);
        
        const critical = logData['critical'] = data['critical'] = _.has(next, 'critical') ? next.critical : null;

        data.log = JSON.stringify({
          section: _.has(next, 'section') ? next.section : data.section,
          data: logData,
          category,
          critical,
          messages: this.getMessageLog(this.messages)
        });
        data.mensagem = _.replace(data.mensagem, '*Mensagem:*', '*Chat:* \n'+this.getTranscript(this.messages, true));

        // if (_.has(data, 'assignee')) data.assignee = 'MEL';

        this.$api({
          url: '/opensupportticket',
            method: 'POST',
            data
          })
          .then(response => {
            console.log('openTicket => ',response);
            if (_.has(response.data, 'issue')) {
              this.data.ticket = {
                id: response.data.issue.issueId,
                key: response.data.issue.issueKey,
                section: _.has(next, 'section') ? next.section : data.section,
                issueType: data.issueType, 
                summary: data.assunto,
                assignee: data.assignee,
                category,
                critical,
                data: _.clone(this.getData),
                messages: this.messages,
              };

              this.$emit('save-ticket', this.data.ticket);

              this.data.history = _.concat([], this.messages);
            }
            if(response.data.retorno==200){
              this.handleChat(next);

              this.trackEvent('support', 'chatbot ticket opened');
              this.loading = false;
            }else if(response.data.retorno==401) {
              this.getout();
              this.$emit('toggle-alert', 'Sua sessão expirou...');
            }else{
              const status = response.data.retorno;
              const node = this.info.nodeId+'.'+status;
              if (_.has(this.script.nodes, node)) {
                this.loading = false;
                this.handleChat(this.script.nodes[node]);
                this.trackEvent('support', 'chatbot ticket opened');
              }else{
                this.$emit('toggle-alert', 'Aguardando resposta do sistema... ⏳');
                // try again in 5s
                this.view.loader = setTimeout(($) => {
                  this.view.loader = null;
                  $.openTicket(message, next);
                }, 5000, this, message, next);
                this.trackEvent('support', 'chatbot ticket failed');
              }
            }
          })
          .catch(error => {
            this.$emit('toggle-alert', 'Aguardando resposta do sistema... ⏳');
            // try again in 5s
            this.view.loader = setTimeout(($) => {
              this.view.loader = null;
              $.openTicket(message, next);
            }, 5000, this, message, next);
            this.trackEvent('support', 'chatbot ticket failed');
            this.handleError(error, false);
          })
          .finally(() => {
          });
      },

      attachTicket (message, next) {
        this.loading = true;

        const attachments = JSON.stringify(_.map(message.value, v => {
          v.file = v.file.substring(v.file.indexOf(',')+1);
          return v;
        }));

        this.updateTicket(message, next, attachments);
        // const data = {
        //   cpf: this.info.cpf,
        //   authTk: this.info.authTk,
        //   issueId: this.data.ticket.key,
        //   section: this.data.ticket.section,
        //   attachments,
        // }

        // this.$api({
        //   url: '/addticketattachments',
        //     method: 'POST',
        //     data
        //   })
        //   .then(response => {
        //     console.log('attachTicket => ',response);

        //     message.value = message.content;

        //     setTimeout(this.updateTicket, 250, message, next);

        //     this.trackEvent('support', 'chatbot interaction attachment');
        //     this.loading = false;
        //   })
        //   .catch(error => {
        //     this.$emit('toggle-alert', 'Aguardando resposta do sistema... ⏳');
        //     // try again in 5s
        //     this.view.loader = setTimeout(($) => {
        //       this.view.loader = null;
        //       $.attachTicket(message, next);
        //     }, 5000, this, message, next);
        //     this.handleError(error, false);
        //   })
        //   .finally(() => {
        //   });
      },

      updateTicket (message, next, attachments=null) {
        this.loading = true;
        const script = _.clone(next);
        if (_.isArray(next)&&!_.isEmpty(next)) next = next[0];

        let data = {
          cpf: this.info.cpf,
          authTk: this.info.authTk,
          issueId: this.data.ticket.key,
        }

        const logData = _.clone(this.logData);

        let category = null;
        if (_.has(next, 'category')) {
          category = _.clone(next.category);
        }else if (_.has(logData, 'category')&&!_.isEqual(logData.category, this.data.ticket.category)) {
          category = _.clone(logData.category);
        }

        let summary = null;
        if (category!=null) {
          this.data.ticket.category = category;
          const testing = this.dev ? '[TESTE] ' : '';
          if (_.has(category, 'value')) {
            data.category = category.value;
            summary = category.value;
          }
          if (_.has(category, 'child')) {
            category.child = this.fillData(category.child);
            data.subcategory = category.child;
            summary += ' - ' + category.child;
          }
          summary = testing + summary;
        }

        if (!logData.description) {
          data.description = _.replace(this.info.mensagem, '*Mensagem:*', '*Chat:* \n'+this.getTranscript(this.messages, true));
          logData.description = true;
        }

        const critical = logData['critical'] = _.has(next, 'critical') ? next.critical : this.data.ticket.critical;
        
        if (_.has(next, 'section')) this.data.ticket.section = next.section;

        const messages = _.drop(this.messages, this.data.history.length);
        const comment = this.getTranscript(messages, true);
        
        const schedule = _.has(next, 'type') && next.type=='calendar' ? moment(next.value.start).toISOString() : null
        data = {
          ...data,
          comment,
          summary,
          public: false,
          log: JSON.stringify({
            section: this.data.ticket.section,
            category: category!=null ? category : this.data.ticket.category,
            critical,
            data: logData,
            messages: this.getMessageLog(this.messages)
          }),
          critical,
          attachments
        }
        if (!_.isNil(schedule)) data['schedule'] = schedule

        console.log('updateTicket...', data);
        this.$api({
          url: '/addticketcomment',
            method: 'POST',
            data
          })
          .then(response => {
            console.log('updateTicket => ', response);

            const ticket = this.data.ticket;

            if (category!=null) {
              ticket.category = category;
            }
            if (critical!=null) {
              ticket.critical = critical;
            }
            ticket.data = _.clone(this.getData);
            ticket.messages = JSON.parse(JSON.stringify(this.messages));

            this.$set(this.data, 'ticket', ticket);
            this.$emit('save-ticket', ticket);
            this.data.history = JSON.parse(JSON.stringify(this.messages));

            this.handleChat(script);

            this.trackEvent('support', 'chatbot interaction comment');
          })
          .catch(error => {
            this.handleChat(script);
            // this.$emit('toggle-alert', 'Aguardando resposta do sistema... ⏳');
            // // try again in 5s
            // setTimeout(($) => {
            //   $.updateTicket(message, next);
            // }, 5000, this, message, next);
            
            this.trackEvent('support', 'chatbot interaction comment');
            this.handleError(error, false);
          })
          .finally(() => {
            this.loading = false;
          });
      },

      assignTicket (message, next) {
        this.loading = true;

        const messages = _.drop(this.messages, this.ticket.messages.length);
        const comment = _.isEmpty(messages) ? null : this.getTranscript(messages, true);
        const logData = _.clone(this.logData);
        const log = _.isNil(comment) ? null : JSON.stringify({
          section: this.data.ticket.section,
          category: _.has(next, 'category') ? next.category : this.data.ticket.category,
          critical: _.has(next, 'critical') ? next.critical : this.data.ticket.critical,
          data: logData,
          messages: this.getMessageLog(this.messages)
        });

        const data = {
          cpf: this.info.cpf,
          authTk: this.info.authTk,
          issueId: this.data.ticket.key,
          comment,
          log,
          public: false,
          assignee: _.has(message, 'assignee') ? message.assignee : 'SUP'
        }

        this.$api({
          url: '/assignticket',
            method: 'PUT',
            data
          })
          .then(response => {
            console.log('assignTicket => ',response);

            if (_.has(next, 'category')) {
              this.data.ticket.category = next.category;
            }
            if (_.has(next, 'critical')) {
              this.data.ticket.critical = next.critical;
            }
            this.data.ticket.data = _.clone(this.getData);

            this.data.ticket = _.assign({}, this.data.ticket, {
              messages: this.messages,
              assignee: 'SUP',
            })
            this.$emit('save-ticket', this.data.ticket);

            this.data.history = JSON.parse(JSON.stringify(this.messages));

            this.response.sup.success = [
              {
                interaction: 'message',
                delay: 500,
                loading: true,
                content: message.content,
                update: _.has(message, 'update') && message.update
              }
            ];
            setTimeout(this.handleChat, 250, _.concat(this.response.sup.success, next));

            this.trackEvent('support', 'chatbot assign support');
          })
          .catch(error => {
            this.$emit('toggle-alert', 'Aguardando resposta do sistema... ⏳');
            // try again in 5s
            this.view.loader = setTimeout(($) => {
              this.view.loader = null;
              $.assignTicket(message, next);
            }, 5000, this, message, next);
            this.handleError(error, false);
          })
          .finally(() => {
            this.loading = false;
          });
      },

      closeTicket (message, cancel) {
        cancel = _.isNil(cancel) ? false : cancel;
        this.close.cancel = cancel;
        
        this.loading = true;

        const messages = [...this.messages, ...(_.has(message, 'content') ? [{...message, interaction: 'message', type: 'text', loading: false }] : []) ];
        const comment = !cancel ? this.getTranscript(_.drop(messages, this.ticket.messages.length), true) : `*${this.name}: Cancelar atendimento*`;
        const logData = _.clone(this.logData);
        const log = _.isNil(comment) ? null : JSON.stringify({
          section: this.data.ticket.section,
          category: this.data.ticket.category,
          critical: this.data.ticket.critical,
          data: logData,
          messages: this.getMessageLog(messages)
        });
        const data = {
          cpf: this.info.cpf,
          authTk: this.info.authTk,
          issueId: this.data.ticket.key,
          comment: comment,
          public: false,
          log
        }

        this.$api({
          url: '/closeticket',
            method: 'POST',
            data
          })
          .then(response => {
            console.log('closeTicket => ',response);

            this.data.ticket = _.assign({}, this.data.ticket, {
              closed: true,
              messages: this.messages
            })
            this.$emit('save-ticket', this.data.ticket);
            this.$emit('close-ticket', this.data.ticket.id);

            this.response.sup.success = [
              {
                interaction: 'message',
                delay: 500,
                loading: true,
                content: cancel ? 'Beleza, até a próxima!' : message.content
              }
            ];
            this.handleChat(this.response['sup']['success'], true);

            this.trackEvent('support', 'chatbot ticket closed');
            this.loading = false;
          })
          .catch(error => {
            console.warn(error);
            this.$emit('toggle-alert', 'Aguardando resposta do sistema... ⏳');
            // try again in 5s
            this.view.loader = setTimeout(($) => {
              this.view.loader = null;
              $.closeTicket(message, cancel);
            }, 5000, this, message, cancel);
            this.handleError(error, false);
          })
          .finally(() => {
          });
      },

      setSelectedSlot (slot, next) {
        this.loading = true;

        const cpf = this.rawCPF(this.user.cpf);
        const token = this.user.auth.token;

        const ticket = this.data.ticket;
        const urgent = ticket.section!='urgent'&&!_.isNil(this.urgent) ? this.urgent : null;
        const label = _.has(ticket, 'category') && _.has(ticket.category, 'label') ? ticket.category.label : null;
        // const key = this.getCalendarKey(this.user);
        const id = slot.id;

        this.$api.get('/selectslotcalendar/'+cpf+'/'+token+'/'+id+'/?ticket_id='+ticket.key+(_.isNil(label) ? '' : '&issue_label='+label))
          .then(response => {
            console.log('setSelectedSlot => ',response);
            if(response.data.retorno==200){
              setTimeout(this.handleChat, 250, _.concat(this.response.calendar.success, next));

              if (!_.isNil(urgent)) {
                // close urgent issue ticket
                const data = {
                  cpf: this.info.cpf,
                  authTk: this.info.authTk,
                  issueId: urgent.key,
                  comment: '*Mel: Atendimento encaminhado no chamado* '+ticket.key,
                  public: false,
                }
                this.$api({
                  url: '/closeticket',
                    method: 'POST',
                    data
                  })
                  .then(response => {
                    console.log('closeTicket =>', response);
                    this.$emit('close-ticket', urgent.id);
                  })
                
                this.data.ticket.section = ticket.section = 'urgent';
                if (_.has(urgent, 'category')) this.data.ticket.category = urgent.category;
              }

              const hive = this.hive;
              const start = moment(slot.start);
              slot['issue'] = ticket.section;
              slot['type'] = slot.type;
              slot['ticket'] = ticket.id;
              slot['title'] = slot.type=='REV' ? 'Revisão agendada' : slot.type=='PUB' ? 'Ação Publicitária' : slot.type=='MAN' ? 'Manutenção agendada' : 'Agendamento';
              slot['text'] = 'Compareça na Colmeia <b>dia '+start.format('DD/MM')+' às '+start.hour()+'h</b><br><a href="'+hive.uri+'" target="_blank">'+hive.address+'</a>. <br>Se precisar, clique aqui para reagendar.';

              if (_.isNil(this.data.ticket.data)) this.data.ticket.data = {};
              this.data.ticket.data = Object.assign(this.data.ticket.data, { calendar: slot, slot: slot.id });

              this.$emit('save-calendar', slot);

              this.trackEvent('help', 'confirm calendar ok');
            }else if(response.data.retorno==404){
              this.handleChat(_.concat([
                {
                  interaction: 'message',
                  delay: 500,
                  loading: true,
                  content: 'Infelizmente o horário selecionado não está mais disponível'
                },
                {
                  interaction: 'message',
                  delay: 2500,
                  loading: true,
                  content: 'Estou atualizando os horários disponíveis, um segundo por favor...'
                },
                {
                  interaction: 'calendar',
                  eventType: slot.type,
                  delay: 500,
                },
              ], next));
              this.trackEvent('help', 'calendar slot taken');
            }else{
              this.handleChat(_.concat([
                {
                  interaction: 'message',
                  delay: 500,
                  loading: true,
                  content: 'Houve um problema ao agendar seu horário, vamos tentar novamente!'
                },
                {
                  interaction: 'message',
                  delay: 2500,
                  loading: true,
                  content: 'Estou atualizando os horários disponíveis, um segundo por favor...'
                },
                {
                  interaction: 'calendar',
                  eventType: slot.type,
                  delay: 500,
                },
              ], next));
              this.trackEvent('help', 'confirm calendar failed');
            }
          })
          .catch(error => {
            this.handleChat({
              type: 'calendar',
              value: 'error',
            });
            this.handleError(error);
          })
          .finally(() => {
            this.loading = false;
          });
      },

      getCalendarSlots (script) {
        this.loading = true;

        const cpf = this.rawCPF(this.user.cpf);
        const token = this.user.auth.token;
        const next = _.first(script);
        let type = _.has(next, 'eventType') ? next.eventType : 'MAN';
        type = type=='REV' && !_.isNil(this.urgent) ? 'MAN' : type;
        const ticket = this.data.ticket.id;
        // const key = this.getCalendarKey(this.user);

        this.$api.get('/getslotscalendar/'+cpf+'/'+token+'/'+type)
          .then(response => {
            console.log('getCalendarSlots => ',response);
            if(response.data.retorno==200){
              console.log(this.data.ticket.id, ticket);
              if (this.data.ticket.id!=ticket) {
                return
              }
              const urgent = this.data.ticket.section=='urgent'||!_.isNil(this.urgent);
              const slots = this.processSlots(_.clone(response.data.eventosLivres), this.data.ticket.critical, type, urgent);
              
              console.log('slots', slots);
              if (_.isEmpty(slots)) {
                this.handleChat([
                  {
                    interaction: 'message',
                    delay: 2500,
                    loading: true,
                    content: 'Estamos sem horários disponíveis na Colmeia no momento.'
                  },
                  {
                    interaction: 'message',
                    delay: 2000,
                    loading: true,
                    content: 'Vou solicitar a abertura de novos horários, um minuto por favor...'
                  },
                  {
                    interaction: 'support',
                    category: 'Solicitação',
                    delay: 500,
                    content: 'Prontinho! Em breve nossa equipe de Suporte Técnico entrará em contato para realizar seu agendamento.',
                    update: true
                  },
                ]);
              }else{
                script[0].action = slots;
                this.requireAction(script);
              }

              this.trackEvent('help', 'get calendar slots ok');
            }else{
              this.$emit('toggle-alert', 'Aguardando resposta do sistema... ⏳');
              // try again in 5s
              this.view.loader = setTimeout(($) => {
                this.view.loader = null;
                $.getCalendarSlots(script);
              }, 7000, this, script);
              this.handleError(error, false);
              this.trackEvent('help', 'get calendar slots failed');
            }
          })
          .catch(error => {
            this.$emit('toggle-alert', 'Aguardando resposta do sistema... ⏳');
            // try again in 5s
            this.view.loader = setTimeout(($) => {
              this.view.loader = null;
              $.getCalendarSlots(script);
            }, 7000, this, script);
            this.handleError(error, false);
          })
          .finally(() => {
            this.loading = false;
          });
      },

      cancelSelectedSlot (script) {
        if (this.schedule==null) {
          this.handleChat(_.tail(script));
          return;
        }
        this.loading = true;

        const cpf = this.rawCPF(this.user.cpf);
        const token = this.user.auth.token;
        // const key = this.getCalendarKey(this.user);
        const id = this.schedule.id;

        this.$api.get('/deleteslotcalendar/'+cpf+'/'+token+'/?slot_id='+id)
          .then(response => {
            console.log('cancelSelectedSlot => ',response);
            if(response.data.retorno==200){
              this.handleChat(_.tail(script));

              this.data.ticket.data = Object.assign(this.data.ticket.data, { calendar: null, slot: null });
              this.$emit('save-calendar', null);

              this.trackEvent('help', 'cancel calendar ok');
            }else{
              this.handleChat([
                {
                  interaction: 'message',
                  delay: 1500,
                  loading: true,
                  content: 'Houve um problema ao cancelar seu horário. Tente novamente, por favor.'
                }
              ]);
              this.trackEvent('help', 'cancel calendar failed');
            }
          })
          .catch(error => {
            this.handleError(error, 'Não foi possível cancelar o horário. Tente novamente, por favor.', true);
          })
          .finally(() => {
            this.loading = false;
          });
      },

      processSlots (slots, critical, type, urgent) {
        let processed = _.orderBy(_.filter(_.map(slots, e => {
          return {
            id: e.id,
            type: e.tipo,
            start: e.inicio,
            end: e.fim,
            critical: _.has(e, 'critical') ? e.critical : 0
          }
        }), slot => { 
          return critical==null&&slot.critical==0 || 
            (critical==slot.critical||slot.critical==0);
        }), ['start', 'critical'], ['asc', 'asc']);
        console.log('slots raw', processed);
        processed = _.reduce(processed, (filtered, slot) => {
          if (filtered.length==0||_.last(filtered).start!=slot.start) filtered.push(slot);
          return filtered;
        }, []);
        processed = _.slice(processed, 0, 4);
        processed = _.groupBy(processed, s => moment(s.start).format('YYYY-MM-DD'));
        processed = _.mapValues(processed, s => {
          return _.keyBy(s, v => moment(v.start).hour() );
        })

        return processed;
      },

      getHistoryHours (script) {
        this.loading = true;

        const cpf = this.rawCPF(this.user.cpf);
        const token = this.user.auth.token;
        const next = _.first(script);

        this.$api
          .get('/getworkhours/'+cpf+'/'+token+'/?version=3&format=json')
          .then(response => {
            console.log('getWorkHours => ',response);
            if(response.data.retorno==200){
              // format history hours
              const average = _.has(response.data, 'media') ? response.data.media : null;
              let history = _.map(_.reject(response.data.ciclos, ['hora', null]), hour => {
                const h = `${hour.dia} ${String(hour.hora).padStart(2, '0')}:00:00`
                const on = hour.horas_validas+hour.horas_compensadas;
                const off = _.floor(1-on, 2);
                const disabled = off<0.1;
                return {
                  id: h,
                  start: h,
                  end: moment(h).add(1, 'h').format('YYYY-MM-DD HH:mm:ss'),
                  disabled,
                  highlight: disabled,
                  color: on>0 ? 'primary' : 'white',
                  alert: disabled ? 'Hora 100% contabilizada' : null,
                  compensated: hour.horas_compensadas,
                  on_off: hour.on_off,
                  off
                }
              });
              history = _.groupBy(history, hour => moment(hour.start).format('YYYY-MM-DD'));
              history = _.mapValues(history, day => {
                return _.keyBy(day, hour => moment(hour.start).hour());
              })
              // limit to 9 days
              let start, end;
              const today = moment().startOf('hour');
              let slots = {};
              _.times(9, (i) => {
                const day = moment(today).subtract(i, 'days').format('YYYY-MM-DD');
                slots[day] = _.has(history, day) ? history[day] : {};
              });
              // fill gaps
              slots = _.mapValues(slots, (hours, day) => {
                return _.times(24, h => {
                  const start = `${day} ${String(h).padStart(2, '0')}:00:00`
                  return _.has(hours, h) ? hours[h] : {
                    id: start,
                    start,
                    end: moment(start).add(1, 'h').format('YYYY-MM-DD HH:mm:ss'),
                    off: 1,
                    compensated: 0,
                    on_off: null,
                    disabled: false,
                    alert: null
                  }
                })
              })
              // highlight hours w/ issues
              const subcategories = _.clone(this.getData.eligible);
              const issues = _.filter(this.tickets, ticket => {
                return (ticket.closedAt==null||moment(today).startOf('day').subtract(9, 'days').isBefore(ticket.closedAt)) && _.has(ticket, 'category') && _.has(ticket.category, 'child') && _.indexOf(subcategories, ticket.category.child)>=0 && _.has(ticket.data, 'calendar') && !!ticket.data.calendar;
              });
              _.each(issues, issue => {
                const schedule = _.has(issue, 'data')&&_.has(issue.data, 'calendar') ? issue.data.calendar.start : null;
                const lastSlot = _.has(issue, 'data')&&_.has(issue.data, 'slots')&&_.size(issue.data.slots)>0 ? 
                  _.isArray(issue.data.slots) ? 
                    _.last(issue.data.slots.sort()) : 
                    _.reduce(issue.data.slots, (last, d) => {
                      const slot = _.last(_.map(d, 'start').sort());
                      if (last==null||moment(slot).isAfter(last)) last = slot;
                      return last;
                    }, null) : 
                  schedule;
                if (issue.closed||!_.isNil(schedule)) {
                  const info = { category: issue.category.child, key: issue.key };
                  const limit = _.isNil(lastSlot)||_.isNil(schedule) ? false : moment(schedule).isAfter(lastSlot);
                  start = _.first(_.keys(slots).sort());
                  start = moment(issue.createdAt).isAfter(start) ? issue.createdAt : start;
                  end = issue.closedAt==null ? today : issue.closedAt;
                  for (let pointer=moment(start); moment(end).isAfter(pointer)&&moment(today).isAfter(pointer); pointer.add(1, 'hours')) {
                    const day = moment(pointer).format('YYYY-MM-DD');
                    const hour = pointer.hour()
                    if (_.has(slots, day)) {
                      const slot = slots[day][hour];
                      if (!_.has(slot, 'off')||!slot.disabled) {
                        const cliff = limit&&moment(lastSlot).isBefore(pointer);
                        // slot.color = cliff ? 'warning' : 'secondary'
                        slot.disabled = cliff||false;
                        slot.alert = cliff ? 'Não é possível solicitar horas após falta/reagendamento.' : null
                        slot.issue = info;
                      }
                    }
                  }
                }
              })
              // filter days w/ expired review
              const reviews = _.reverse(_.map(this.reviews.history, 'data').sort());
              const delayed = 30-moment(reviews[0]).diff(reviews[1], 'days');
              start = _.first(_.keys(slots).sort());
              if (moment(start).isBefore(reviews[0])&&delayed<0) {
                let pointer=moment(reviews[0]).add(delayed, 'days').startOf('day');
                for (pointer; moment(reviews[0]).isAfter(pointer); pointer.add(1, 'hours')) {
                  const day = moment(pointer).format('YYYY-MM-DD');
                  const hour = pointer.hour()
                  if (_.has(slots, day)) {
                    const slot = slots[day][hour];
                    if (!slot.disabled) {
                      slot.color = 'error'
                      slot.alert = 'Não é possível solicitar compensação para dias com Revisão em atraso'
                      slot.disabled = true;
                    }
                  }
                }
              }

              // filter days w/ pending online review
              const onlineReview = _.find(this.tickets, ticket => {
                return (ticket.section=='review'&&!ticket.closed) &&
                  ((_.has(ticket.category, 'child')&&ticket.category.child=='Revisão Online'&&(!_.has(ticket.data, 'status')||ticket.data.status==0)) || 
                  (_.has(ticket.data, 'status')&&ticket.data.status==2));
              });
              if (!_.isNil(onlineReview)) {
                const pendingAt = _.has(onlineReview.data, 'status')&&onlineReview.data.status==2&&_.has(onlineReview.data, 'dt_status') ? onlineReview.data.dt_status : false;
                start = _.first(_.keys(slots).sort());
                start = pendingAt!==false ? pendingAt : onlineReview.createdAt;
                end = onlineReview.closedAt==null ? today : onlineReview.closedAt;
                for (let pointer=moment(start); moment(end).isAfter(pointer)&&moment(today).isAfter(pointer); pointer.add(1, 'hours')) {
                  const day = moment(pointer).format('YYYY-MM-DD');
                  const hour = pointer.hour();
                  if (_.has(slots, day)) {
                    const slot = slots[day][hour];
                    if (!slot.disabled) {
                      slot.color = 'warning';
                      slot.disabled = true;
                      slot.alert = pendingAt!==false ? 'Agende e faça sua revisão para socilitar compensação.' : 'Não é possível solicitar compensação até completar a revisão';
                    }
                  }
                }
              }

              // filter days for previously requested hours
              const days = _.reduce(_.filter(this.tickets, t => t.section=='hours'&&moment(today).startOf('day').subtract(9, 'days').isBefore(t.createdAt)), (result, ticket) => {
                if (_.has(ticket.data, 'hours')) {
                  _.each(ticket.data.hours, hour => {
                    const h = _.isString(hour) ? _.split(hour, '|')[0] : _.has(hour, 'start') ? hour.start : null;
                    if (!_.isNil(h)) result = _.union(result, [moment(h).format('YYYY-MM-DD')]);
                  });
                }
                return result;
              }, []);
              _.each(days, day => {
                if (_.has(slots, day)) {
                  _.each(slots[day], slot => {
                    if (!slot.disabled) {
                      slot.disabled = true;
                      slot.alert = 'Já foi solicitada compensação de horas para este dia.';
                    }
                  })
                }
              })

              // filter last 2 days to wait cached hours
              start = moment(today).startOf('day').subtract(1, 'days');
              end = moment(today).endOf('day').startOf('hour');
              for (let pointer=moment(start); moment(pointer).isSameOrBefore(end); pointer.add(1, 'hours')) {
                  const day = moment(pointer).format('YYYY-MM-DD');
                  const hour = pointer.hour();
                  if (_.has(slots, day)) {
                    const slot = slots[day][hour];
                    if (!slot.disabled) {
                      slot.disabled = true;
                      slot.alert = 'É preciso aguardar 2 dias para que o Buzzer envie as horas represadas';
                    }
                  }
              }
              
              console.log(slots, issues, average);
              script[0].action = slots;
              script[0].average = average;
              this.requireAction(script);

              this.trackEvent('help', 'get history hours ok');
            }else{
              this.$emit('toggle-alert', 'Aguardando resposta do sistema... ⏳');
              // try again in 5s
              this.view.loader = setTimeout(($) => {
                this.view.loader = null;
                $.getHistoryHours(script);
              }, 7000, this, script);
              this.handleError(error, false);
              this.trackEvent('help', 'get history hours failed');
            }
          })
          .catch(error => {
            this.$emit('toggle-alert', 'Aguardando resposta do sistema... ⏳');
            // try again in 5s
            this.view.loader = setTimeout(($) => {
              this.view.loader = null;
              $.getHistoryHours(script);
            }, 7000, this, script);
            this.handleError(error, false);
          })
          .finally(() => {
            this.loading = false;
          });
      },

      formatBenefits (data, key) {
        return _.orderBy(_.map(data, benefit => {
          let text;
          switch (key) {
            case 'theatres':
              const { theatre, show, date } = benefit.beneficio;
              text = `*${show}* \n ${theatre} \n ${moment.utc(date).local().format('DD/MM HH:mm')}`;
              break;
          
            default:
              text = benefit.beneficio;
              break;
          }
          return { ...benefit, text }
        }), ['dt_inicio'], ['asc']);
      },

      getBenefits (script) {
        this.loading = true;
        
        const cpf = this.rawCPF(this.user.cpf);
        const token = this.user.auth.token;
        const config = _.first(script);
        const key = this.info.section;
        
        const showOptions = (benefits) => {
          const message = {
            "interaction": "button",
            "delay": 500,
            "action": _.map(_.clone(benefits), benefit => {
              return {
                "text": benefit.text,
                "value": config.next,
                "id": benefit.id_beneficio,
                "data": ["benefit", "id"],
                "ticket": false
              }
            })
          };

          this.handleChat([message, ..._.tail(script)]);
        }
        // if (_.has(this.data.temp, 'benefits')) {
        //   showOptions(this.data.temp.benefits);
        //   return;
        // }

        const data = {
          cpf,
          authTk: token,
          key,
        };

        this.$api
          .get('/driver/club', { params: data })
          .then(response => {
            console.log('getBenefits => ',response);
            if(response.data.retorno==200){
              const benefits = this.data.temp.benefits = _.keyBy(this.formatBenefits(response.data.benefits, key), 'id_beneficio');
              this.data.temp.benefit = _.size(benefits)==1 ? _.first(_.values(benefits)).id_beneficio : null;
              
              if (_.has(config, 'options')&&config.options) {
                showOptions(benefits);
              }else{
                if (_.has(config, 'next')) {
                  this.handleChat(config.next);
                }else{
                  this.handleChat(_.tail(script));
                }
              }

              this.trackEvent('help', 'chatbot getBenefits');
            }else if(response.data.retorno==401) {
              this.getout();
              this.toggleToast(
                true,
                'Sua sessão expirou...',
                5000,
                false
              );
            }else{
              const status = response.data.retorno;
              const node = this.info.nodeId+'.'+status;
              if (_.has(this.script.nodes, node)) {
                this.loading = false;
                this.handleChat(this.script.nodes[node]);
                this.trackEvent('support', 'chatbot getBenefits '+status);
              }else{
                this.$emit('toggle-alert', 'Aguardando resposta do sistema... ⏳');
                // try again in 7s
                this.view.loader = setTimeout(($) => {
                  this.view.loader = null;
                  $.getBenefits(script);
                }, 7000, this, script);
                this.handleError(error, false);
                this.trackEvent('help', 'chatbot getBenefits failed');
              }
            }
          })
          .catch(error => {
            this.$emit('toggle-alert', 'Aguardando resposta do sistema... ⏳');
            // try again in 7s
            this.view.loader = setTimeout(($) => {
              this.view.loader = null;
              $.getBenefits(script);
            }, 7000, this, script);
            this.handleError(error, false);
          })
          .finally(() => {
            this.loading = false;
          });
      },

      requestBenefit (script) {
        this.loading = true;
        
        const cpf = this.rawCPF(this.user.cpf);
        const token = this.user.auth.token;
        const benefit = _.clone(this.selectedBenefit);
        this.data.temp.benefit = benefit;
        this.data.temp.benefits = _.keys(this.data.temp.benefits);

        const data = {
          cpf,
          authTk: token,
          benefit_id: benefit.id_beneficio
        };

        this.$api
          .post('/driver/club', data)
          .then(response => {
            console.log('requestBenefit => ',response);
            if(response.data.retorno==200){
              this.handleChat(_.tail(script));

              this.trackEvent('help', 'chatbot requestBenefit');
            }else if(response.data.retorno==401) {
              this.getout();
              this.toggleToast(
                true,
                'Sua sessão expirou...',
                5000,
                false
              );
            }else{
              const status = response.data.retorno;
              const node = this.info.nodeId+'.'+status;
              if (_.has(this.script.nodes, node)) {
                this.loading = false;
                this.handleChat(this.script.nodes[node]);
                this.trackEvent('support', 'chatbot requestBenefit '+status);
              }else{
                this.$emit('toggle-alert', 'Aguardando resposta do sistema... ⏳');
                // try again in 7s
                this.view.loader = setTimeout(($) => {
                  this.view.loader = null;
                  $.requestBenefit(script);
                }, 7000, this, script);
                this.handleError(error, false);
                this.trackEvent('help', 'chatbot requestBenefit failed');
              }
            }
          })
          .catch(error => {
            this.$emit('toggle-alert', 'Aguardando resposta do sistema... ⏳');
            // try again in 7s
            this.view.loader = setTimeout(($) => {
              this.view.loader = null;
              $.requestBenefit(script);
            }, 7000, this, script);
            this.handleError(error, false);
          })
          .finally(() => {
            this.loading = false;
          });
      },

      getEvents (script) {
        this.loading = true;
        
        const cpf = this.rawCPF(this.user.cpf);
        const token = this.user.auth.token;

        const data = {
          cpf,
          token,
        };
        
        console.log('getEvents...', data);
        this.$api
          .get('driver/events/', { params: data })
          .then(response => {
            console.log('getEvents => ',response);
            // TODO: send message
          })
          .catch(error => {
            if(error.response.status==401){
              this.getout();
              this.handleError(error, 'Sua sessão expirou...');
            }else{
              this.handleError(error, 'Aguardando resposta do sistema...');
              // setTimeout(($, cpf, token) => {
              //   $.getEvents(cpf, token);
              // }, 5000, this, cpf, token);
            }
          })
          .finally(() => {
            this.loading = false;
          });
      },

      rsvpEvent (script, accepted) {
        this.loading = true;
        
        const cpf = this.rawCPF(this.user.cpf);
        const token = this.user.auth.token;
        const id_parceiro = this.user.profile.partner_id;
        const id_flashmob = this.getData.id_flashmob;
        const data = {
          cpf,
          token,
          id_parceiro,
          id_flashmob,
          accepted
        };

        console.log('rsvpEvent...', data);

        this.$api
          .post('/driver/events', data)
          .then(response => {
            console.log('rsvpEvent => ', response);
            this.handleChat(_.tail(script));

          })
          .catch(error => {
            const node = this.info.nodeId+'.'+error.response.status;
            if (_.has(this.script.nodes, node)) {
              this.loading = false;
              this.handleChat(this.script.nodes[node]);
              this.trackEvent('support', 'chatbot requestBenefit '+status);
            }else if (error.response.status==401) {
              this.getout();
              this.toggleToast(
                true,
                'Sua sessão expirou...',
                5000,
                false
              );
            }else{
              this.handleError(error, 'Erro desconhecido. Vamos investigar o que houve! 😊', true);
              // this.$emit('toggle-alert', 'Aguardando resposta do sistema... ⏳');
              // try again in 7s
              // this.view.loader = setTimeout(($) => {
              //   this.view.loader = null;
              //   $.getBenefits(script);
              // }, 7000, this, script);
            }
          })
          .finally(() => {
            this.loading = false;
          });
      },

      sendEmail (script) {
        this.loading = true;

        const cpf = this.rawCPF(this.user.cpf);
        const token = this.user.auth.token;
        const config = _.first(script);

        const regex = /\{\{(.*?)\}\}/gim;
        const $ = this;
        config.data = _.mapValues(config.data, data => {
          data = data.replace(/\*([^\*]+)\*/gim, '<b>$1</b>')
          return _.replace(data, regex, (match, key) => {
            return _.get($, key);
          });
        });

        const data = {
          cpf,
          authTk: token,
          message: {
            from: 'Mobees <suporte@mobees.com.br>',
            ...config.data
          }
        };

        this.$api({
          url: '/driver/sendemail',
            method: 'POST',
            data
          })
          .then(response => {
            console.log('sendemail => ',response);
            if(response.data.retorno==200){
              this.handleChat(_.tail(script));

              this.trackEvent('help', 'chatbot send email ok');
            }else if(response.data.retorno==401) {
              this.getout();
              this.toggleToast(
                true,
                'Sua sessão expirou...',
                5000,
                false
              );
            }else{
              this.$emit('toggle-alert', 'Aguardando resposta do sistema... ⏳');
              // try again in 7s
              this.view.loader = setTimeout(($) => {
                this.view.loader = null;
                $.sendEmail(script);
              }, 7000, this, script);
              this.handleError(error, false);
              this.trackEvent('help', 'chatbot send email failed');
            }
          })
          .catch(error => {
            this.$emit('toggle-alert', 'Aguardando resposta do sistema... ⏳');
            // try again in 7s
            this.view.loader = setTimeout(($) => {
              this.view.loader = null;
              $.sendEmail(script);
            }, 7000, this, script);
            this.handleError(error, false);
          })
          .finally(() => {
            this.loading = false;
          });
      },

      setCookie (script) {
        const config = _.first(script);
        const regex = /\{\{(.*?)\}\}/gim;
        const $ = this;
        config.value = _.mapValues(config.value, data => {
          data = data.replace(/\*([^\*]+)\*/gim, '<b>$1</b>')
          return _.replace(data, regex, (match, key) => {
            return _.get($, key);
          });
        });
        
        this.setStorage(config.key, config.value);

        this.handleChat(_.tail(script));
      },

      updateProfile (field, value) {
        this.loading = true;
        const cpf = this.rawCPF(this.user.cpf);
        const token = this.user.auth.token;

        const data = { [this.dictionary.profile[field]]: value };

        this.$api
          .post('/updateprofilefield', {
            authTk: token,
            cpf,
            data
          })
          .then(response => {
            console.log('updateProfile => ', response);
            if(response.data==200){
              this.user.profile[field] = value;

              this.trackEvent('chatbot', 'updated profile');
            }else{
              this.view.loader = setTimeout(($) => {
                this.view.loader = null;
                $.updateProfile(field, value);
              }, 7000, this);
              this.trackEvent('chatbot', 'update profile error');
              this.handleError(error, false);
            }
          })
          .catch(error => {
            this.view.loader = setTimeout(($) => {
              this.view.loader = null;
              $.updateProfile(field, value);
            }, 7000, this);
            this.trackEvent('chatbot', 'update profile error');
            this.handleError(error, false);
          })
          .finally(() => {
            this.loading = false;
          })
      },
    },

    filters: {
      readableDate (date) {
        console.log(date);
        date = moment(date);
        return date.format('DD/MM') + ' às ' + date.hour() +'h';
      }
    },

    beforeDestroy () {
      console.log('cleanup chatbot');
      if (this.view.loader!=null) clearTimeout(this.view.loader);
    },

    mounted () {
      this.startChat();
    }
  }

</script>